export default [
  {
    path: '/home',
    name: 'admin-home',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/dashboard/home/admin-home'),
    meta: {
      title: 'Admin Home',
      requireAuth: true,
      roles: ['admin']
    },
  },
  {
    path: '/profile',
    name: 'admin-profile',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/dashboard/profile/profile'),
    meta: {
      title: 'Admin Profile',
      requireAuth: true,
      roles: ['admin']
    },
  },
  {
    path: '/admin/admin-users',
    name: 'admin-users',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/admin-users/list'),
    meta: {
      title: 'Administrators',
      requireAuth: true,
      roles: ['admin'],
      resource: 'admin',
      permissions: ['admin:CanList'],
      breadcrumb: [
        {
          text: 'Administrators',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/admin-user/create',
    name: 'create-admin-user',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/admin-users/create'),
    meta: {
      title: 'Create Administrator',
      requireAuth: true,
      resource: 'admin',
      permissions: ['admin:CanAddNew'],
      roles: ['admin'],
    },
  },
  {
    path: '/admin/admin-user/:id',
    name: 'admin-user-single',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/admin-users/view'),
    meta: {
      title: 'Administrator',
      requireAuth: true,
      roles: ['admin'],
      resource: 'admin',
      permissions: ['admin:CanView'],
      breadcrumb: [
        {
          text: 'Administrators',
          to: { name: 'admin-users' }
        },
        {
          text: 'Administrator Details',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/customers',
    name: 'users',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/customers/list'),
    meta: {
      title: 'Users',
      requireAuth: true,
      roles: ['admin'],
      resource: 'users',
      permissions: ['users:CanList'],
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/customers/create',
    name: 'create-user',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/customers/create'),
    meta: {
      title: 'Create User',
      requireAuth: true,
      resource: 'users',
      permissions: ['users:CanAddNew'],
      roles: ['admin'],
    },
  },
  {
    path: '/admin/customers/:id',
    name: 'user-single',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/customers/view'),
    meta: {
      title: 'Users',
      requireAuth: true,
      roles: ['admin'],
      resource: 'users',
      permissions: ['users:CanView'],
      breadcrumb: [
        {
          text: 'Users',
          to: { name: 'users' }
        },
        {
          text: 'User Details',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/notifications',
    name: 'admin-notifications-list',
    component: () => import('@/pages/admin/notifications/list'),
    meta: {
      title: 'Notifications',
      requireAuth: true,
      roles: ['admin'],
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
          to: { name: 'notifications' }
        }
      ]
    }
  }
]
