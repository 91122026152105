export default [
  // Resources
  {
    path: '/admin/resources',
    name: 'admin-resources',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/resource/list-resources'),
    meta: {
      title: 'Admin Permission Resources',
      requireAuth: true,
      resource: 'resource',
      permissions: ['resource:CanList'],
      breadcrumb: [
        {
          text: 'Resources',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/new-resource',
    name: 'admin-new-resource',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/resource/new-resource'),
    meta: {
      title: 'New Resource',
      requireAuth: true,
      resource: 'resource',
      permissions: ['resource:CanAddNew'],
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' }
        },
        {
          text: 'New Resource',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/update-resource/:id',
    name: 'admin-update-resource',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/resource/update-resource'),
    meta: {
      title: 'Update Resource',
      requireAuth: true,
      resource: 'resource',
      permissions: ['resource:CanView', 'resource_action:CanView'],
      breadcrumb: [
        {
          text: 'Resources',
          to: { name: 'admin-resources' }
        },
        {
          text: 'Update Resource',
          active: true,
        }
      ],
    },
  },

  //  Policy
  {
    path: '/admin/policies',
    name: 'admin-policies',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/policies/list-policies'),
    meta: {
      title: 'Admin Permission Policies',
      resource: 'policy',
      permissions: ['policy:CanList'],
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Policies',
          active: true,
        }
      ],
    },
  },

  // Admin User Roles
  {
    path: '/admin/user-roles',
    name: 'admin-user-roles',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/roles/list-user-roles'),
    meta: {
      title: 'Admin User Roles',
      requireAuth: true,
      permissions: ['user_role:CanList'],
      breadcrumb: [
        {
          text: 'User Roles',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/new-user-roles',
    name: 'admin-new-user-roles',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/roles/add-user-roles'),
    meta: {
      title: 'Add User Roles',
      permissions: ['user_role:CanAddNew'],
      requireAuth: true,
      breadcrumb: [
        {
          text: 'User Roles',
          to: { name: 'admin-user-roles' }
        },
        {
          text: 'New User Role',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/update-user-roles/:id',
    name: 'admin-update-user-roles',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/roles/update-user-roles'),
    meta: {
      title: 'Add User Roles',
      permissions: ['user_role:CanView'],
      requireAuth: true,
      breadcrumb: [
        {
          text: 'User Roles',
          to: { name: 'admin-user-roles' }
        },
        {
          text: 'Update User Role',
          active: true,
        }
      ],
    },
  },
  {
    path: '/policies/:id',
    name: 'admin-view-policies',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/policies/view-policy'),
    meta: {
      title: 'View Policy',
      requireAuth: true,
      permissions: ['policy:CanView'],
      breadcrumb: [
        {
          text: 'Policies',
          to: { name: 'admin-policies' }
        },
        {
          text: 'View Policy',
          active: true,
        }
      ],
    }
  },
  {
    path: '/create-policy',
    name: 'admin-new-policy',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/permission/policies/new-policy'),
    meta: {
      title: 'New Policy',
      permissions: ['policy:CanAddNew'],
      requireAuth: true,
      breadcrumb: [
        {
          text: 'Policies',
          to: { name: 'admin-policies' }
        },
        {
          text: 'New Policy',
          active: true,
        }
      ],
    },
  }
]
