export default [
  {
    path: '/admin/orders',
    name: 'list-orders',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/orders/list'),
    meta: {
      title: 'List Orders',
      requireAuth: true,
      roles: ['admin'],
      resource: 'orders',
      permissions: ['orders:CanList'],
      breadcrumb: [
        {
          text: 'Orders',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/orders/verify',
    name: 'admin-order-checkout-verify',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/orders/verify'),
    meta: {
      requireAuth: true,
      title: 'Verify Order',
      roles: ['admin']
    },
  },
  {
    path: '/admin/orders/:id',
    name: 'admin-order-detail',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/orders/view'),
    meta: {
      requireAuth: true,
      title: 'Orders Detail',
      roles: ['admin'],
      resource: 'orders',
      permissions: ['orders:CanView'],
      breadcrumb: [
        {
          text: 'Orders',
          to: { name: 'list-orders' }
        },
        {
          text: 'Order Details',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/sales/report',
    name: 'list-sales-report',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/reports'),
    meta: {
      title: 'List Sales Report',
      requireAuth: true,
      roles: ['admin'],
      resource: 'orders',
      permissions: ['orders:CanGenerateReport'],
      breadcrumb: [
        {
          text: 'Sales Report',
          active: true,
        }
      ],
    },
  },
]
