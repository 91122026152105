<template>
  <section class="w-100">
    <div class="d-flex align-items-center justify-content-center mt-5">
      <transition :name="settings.routerAnimation" mode="out-in">
        <div class="mt-5 mx-auto admin-auth-wrapper">
          <div class="d-flex align-items-center align-items-center flex-column mb-3">
            <img width="80" src="@/core/assets/images/logo.png" alt="logo" />
            <h5 class="mt-3 text-center text-danger">Administrators only</h5>
          </div>

          <div>
            <router-view :key="$route.fullPath" />
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthAdminLayout',
  computed: mapState(['settings'])
}
</script>

<style lang="scss">
  .admin-auth-wrapper {
    width: 50%;

    @media (max-width: 390px) {
      width: 80%;
    }
  }
</style>
