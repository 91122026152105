export default [
  {
    path: '/admin/courier-services',
    name: 'admin-list-courier-services',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/courier-services/list'),
    meta: {
      title: 'List Courier Services',
      requireAuth: true,
      roles: ['admin'],
      resource: 'courier_services',
      permissions: ['courier_services:CanList'],
      breadcrumb: [
        {
          text: 'Courier Services',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/pickup-stations',
    name: 'admin-list-pickup-stations',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/pickup-stations/list'),
    meta: {
      requireAuth: true,
      title: 'Pickup Stations',
      roles: ['admin'],
      resource: 'pickup_stations',
      permissions: ['pickup_stations:CanView'],
      breadcrumb: [
        {
          text: 'Pickup Stations',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/coupons',
    name: 'admin-coupons',
    component: () => import('@/pages/admin/coupons/list.vue'),
    meta: {
      action: "read",
      pageTitle: 'Coupons',
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Coupons',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Coupons",
      showPagination: true,
      roles: ["admin"],
      resource: 'coupons',
      permissions: ["coupons:CanList"]
    },
  },
  {
    path: '/admin/bank-payments',
    name: 'admin-bank-payments',
    component: () => import('@/pages/admin/bank-payments/list.vue'),
    meta: {
      action: "read",
      pageTitle: 'Bank Payments',
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'admin-home' },
        },
        {
          text: 'List Bank Payments',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Admin List Bank Payments",
      showPagination: true,
      roles: ["admin"],
      resource: 'bank_payments',
      permissions: ["bank_payments:CanList"]
    },
  },
]
