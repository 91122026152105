import Vue from 'vue';
import Router from 'vue-router';
import authRoutes from './routes/auth';
import MainLayout from '@/layouts/Main';
import AdminAuthLayout from '@/layouts/AdminAuth';
import orderRoutes from '@/router/admin/routes/orders';
import storesRoutes from '@/router/admin/routes/stores';
import dashboardRoutes from '@/router/admin/routes/dashboard';
import adminProductRoutes from '@/router/admin/routes/products';
import adminSettingsRoutes from '@/router/admin/routes/settings';
import adminPermissionRoutes from '@/router/admin/routes/permission';
import adminMiscellaneousRoutes from '@/router/admin/routes/misc';

import { routeGuard } from '@/core/utils/utils';
import { routeMiddleware, sharedPages } from '@/core/utils/router';

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'home',
      component: MainLayout,
      beforeEnter: routeGuard,
      children: [...dashboardRoutes],
    },
    {
      path: '/auth',
      component: AdminAuthLayout,
      redirect: '/',
      children: [...authRoutes],
    },
    {
      path: '/products',
      component: MainLayout,
      beforeEnter: routeGuard,
      children: adminProductRoutes,
    },
    {
      path: '/stores',
      component: MainLayout,
      beforeEnter: routeGuard,
      children: storesRoutes,
    },
    {
      path: '/orders',
      component: MainLayout,
      beforeEnter: routeGuard,
      children: orderRoutes,
    },
    {
      path: '/permissions',
      component: MainLayout,
      beforeEnter: routeGuard,
      children: [...adminPermissionRoutes],
    },
    {
      path: '/settings',
      component: MainLayout,
      beforeEnter: routeGuard,
      children: adminSettingsRoutes
    },
    {
      path: '/',
      component: MainLayout,
      beforeEnter: routeGuard,
      children: [...adminMiscellaneousRoutes],
    },
    {
      path: '/notifications',
      name: 'notifications-list',
      component: () => import('@/pages/consumer/account/notifications/list'),
      meta: {
        title: 'Notifications',
        requireAuth: true,
        breadcrumb: [
          {
            text: 'Notifications',
            active: true,
            to: { name: 'notifications' }
          }
        ]
      }
    }
  ],
});

(sharedPages || []).forEach(route => {
  router.addRoute(route)
})

router.beforeEach(routeMiddleware);

router.onError()

export default router
