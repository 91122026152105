export default [
  {
    path: '/admin/settings',
    name: 'admin-general-settings',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/settings/general'),
    meta: {
      title: 'General Settings',
      requireAuth: true,
      resource: 'settings',
      permissions: ['settings:CanView'],
      breadcrumb: [
        {
          text: 'General Settings',
          active: true,
        }
      ],
      roles: ['admin']
    },
  },
]
