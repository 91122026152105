export default [
  {
    path: '/admin/stores',
    name: 'stores',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/stores/list'),
    meta: {
      title: 'Stores',
      requireAuth: true,
      roles: ['admin'],
      resource: 'users',
      permissions: ['users:CanList'],
      breadcrumb: [
        {
          text: 'Stores',
          active: true,
        }
      ],
    },
  },
  {
    path: '/admin/stores/new-store',
    name: 'create-store',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/stores/create'),
    meta: {
      title: 'Create New Store',
      requireAuth: true,
      resource: 'users',
      permissions: ['users:CanAddNew'],
      roles: ['admin'],
    },
  },
  {
    path: '/admin/stores/:id/details',
    name: 'admin-single-store',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/stores/view'),
    meta: {
      title: 'Store Details',
      requireAuth: true,
      roles: ['admin'],
      resource: 'users',
      permissions: ['users:CanView'],
      breadcrumb: [
        {
          text: 'Stores',
          to: { name: 'stores' }
        },
        {
          text: 'Store Details',
          active: true,
        }
      ],
    },
  },
]
