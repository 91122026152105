export default [
  {
    path: '/admin/products/types',
    name: 'list-product-types',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/product-types'),
    meta: {
      title: 'List Product Types',
      requireAuth: true,
      roles: ['admin'],
      resource: 'product_types',
      permissions: ['product_types:CanList'],
      breadcrumb: [
        {
          text: 'Product Types',
          active: true,
        }
      ],
    },
  }
]
