export default [
  {
    path: '/auth/login',
    name: 'general-login',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/auth/login'),
    meta: {
      title: 'Sign In',
    },
  },
  {
    path: '/auth/mfa/authentication',
    name: 'admin-mfa-authentication',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/auth/mfa-authentication'),
    meta: {
      title: 'Admin MFA Authentication',
    },
  },
  {
    path: '/auth/mfa',
    name: 'admin-mfa',
    component: () => import(/* webpackPrefetch: true */ '@/components/shared/auth/mfa'),
    meta: {
      title: 'Admin Mfa',
    },
  },
  {
    path: '/auth/mfa/activation',
    name: 'mfa-activation',
    component: () => import(/* webpackPrefetch: true */ '@/components/shared/auth/mfa-activation'),
    meta: {
      title: 'Admin Mfa Activation',
    },
  },
  {
    path: '/auth/mfa/activation-app',
    name: 'mfa-activation-app',
    component: () => import(/* webpackPrefetch: true */ '@/components/shared/auth/mfa-activation-app'),
    meta: {
      title: 'Admin Mfa Activation',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'admin-forgot-password',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/auth/forgot-password'),
    meta: {
      title: 'Admin Forgot Password',
    },
  },
  {
    path: '/auth/reset-password',
    name: 'admin-reset-password',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/auth/reset-password'),
    meta: {
      title: 'Admin Forgot Password',
    },
  },
  {
    path: '/auth/mfa-reset-password/:mfa_method',
    name: 'admin-mfa-reset-password',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/auth/mfa-reset-password'),
    meta: {
      title: 'Admin Mfa Password Reset',
    },
  },
  {
    path: '/auth/verify/phone',
    name: 'admin-verify-phone',
    component: () => import(/* webpackPrefetch: true */ '@/pages/admin/auth/verify-phone'),
    meta: {
      title: 'Admin Verify Password',
    },
  },
]
